export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'leet-user',
        email: 'leet-user@leetcarbon.space',
        authority: ['user'],
        password: 'Leet@dev-demo54138924',
        accountUserName: 'leet-user@leetcarbon.space',
    },
]
